.ant-modal-content {
  /* background-color: transparent; */
  border-radius: 12px;
}

.ant-drawer-content {
  /* background-color: white; */
  border-radius: 24px 24px 0px 0px;
}

.custom-styles {
  --ReactInputVerificationCode-itemWidth: 56px;
  --ReactInputVerificationCode-itemHeight: 56px;
}

.css-jxah1j {
  box-shadow: inset 0 0 0 0px #ffffff;
}

.ReactInputVerificationCode__item.css-jxah1j {
  border: 2px solid #000000;
}

.ReactInputVerificationCode__item.css-jxah1j.is-active {
  border: 2px solid #002dcb;
  box-shadow: inset 0 0 0 0px #ffffff;
}
